import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import RevealPage from "./pages/home/RevealPage";
import HomePage from "./pages/home/HomePage";
import ConfigPage from "./pages/home/ConfigPage";

function App() {

  return (
    <Router>
      <Routes>
        <Route path="/" exact element={ <HomePage />} />
        <Route path="/reveal" element={ <RevealPage /> } />
        <Route path="/configuration" element={ <ConfigPage /> } />
      </Routes>
    </Router>
  );
}

export default App;
