import { Link } from "react-router-dom";
import { clearRevealArtworks, closeMonitor } from "../../services/Reveal.service";


export default function ConfigPage() {

    const confirmClearQueue = async () => {
        const response = window.confirm("current queue is going to be deleted, do you want to continue?");
        if (response) {
            try {
                await clearRevealArtworks();
                alert('queue was successfully removed')
            } catch(error) {
                console.log(error);
                alert('there was an error cleaning the queue');
            }
        }
    }

    const confirmCloseMonitor = async() => {
        const response = window.confirm("current monitor is goint to be close, do you want to continue?");
        if(response) {
            try {
                await closeMonitor();
                alert('monitor was successfully closed')
            } catch(error) {
                console.log(error);
                alert('there was an error closing monitor');
            }
        }
    }

    return (
        <div className="d-flex flex-column justify-content-center align-items-center">
            <h1 className="my-5">Configuration</h1>
            <div className="mb-3">
                <button type="button" className="btn btn-danger" onClick={confirmClearQueue}>clear queue</button>
            </div>
            <div className="mb-3">
                <button type="button" className="btn btn-danger" onClick={confirmCloseMonitor}>close monitor</button>
            </div>
            <div>
                <Link to="/">
                    <a>Go back</a>
                </Link>
            </div>
        </div>
    );
}