import { Link } from "react-router-dom";

export default function HomePage() {

    return (
        <div className="d-flex flex-column justify-content-center align-items-center">
            <h1 className="my-5">Home</h1>
            <div className="mb-3">
                <Link to="/reveal">
                    <a>Reveal Page</a>
                </Link>
            </div>
            <div>
                <Link to="/configuration">
                    <a>Configuration</a>
                </Link>
            </div>
        </div>
    );
}