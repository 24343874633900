import axios from "axios";

const DOMAIN = process.env.REACT_APP_API_URL;
const SOCKET = process.env.REACT_APP_SOCKET_URL;

export const getRevealArtworks = async (token) => {
    var config = {
        method: 'get',
        url: `${DOMAIN}/api/sellers/monitor-reveal-artwork`,
        headers: { }
    };

    return axios(config);
};

export const getIsFinalized = async () => {
    var config = {
        method: 'get',
        url: `${DOMAIN}/api/sellers/monitor-finalized`,
        headers: { }
    };

    return axios(config);
}

export const clearRevealArtworks = async () => {
    var config = {
        method: 'delete',
        url: `${DOMAIN}/api/sellers/monitor-clear-reveal`,
        headers: { }
    };

    return axios(config);
}

export const closeMonitor = async() => {
    var config = {
        method: 'post',
        url: `${SOCKET}/api/close-monitor`,
        headers: { }
    };

    return axios(config);
}