import React, { useEffect, useState } from "react";
import { getRevealArtworks, getIsFinalized } from "../../services/Reveal.service";
import io from 'socket.io-client';

export default function RevealPage() {

    const SOCKET = process.env.REACT_APP_SOCKET_URL;
    const [artworksToReveal, setArtworksToReveal] = useState({});
    const [retryIntervalActive, setRetryIntervalActive] = useState(false);
    const [callIsFinalized, setCallIsFinalized] = useState(false);
    const [mona, setMona] = useState(null);

    const fetchArtworks = async () => {
        try {
            const artworks = await getRevealArtworks();
            setArtworksToReveal(artworks.data);
            setRetryIntervalActive(false);
            setMona(null);
        } catch (error) {
            console.log(422);
        }
    }

    const fetchFinalized = async () => {
        try {
            const response = await getIsFinalized();
            if(response.data.finalized) {
                setCallIsFinalized(false);
                setRetryIntervalActive(true);
                setArtworksToReveal({});
            }
        } catch (error) {
            console.log(error);
        }
    }

    const printMona = () => {

        try {
            let img = document.createElement('img');
            img.src = artworksToReveal.artwork.compressedImageUrl;
            img.onload = () => {
                setMona(artworksToReveal.artwork.compressedImageUrl);
            }
        } catch(error) {
            setMona(artworksToReveal.artwork.compressedImageUrl);
        }

        if(artworksToReveal.isFinalized) {
            setCallIsFinalized(true);
        } else {
            setRetryIntervalActive(true);
        }
    }

    useEffect(() => {
        if (retryIntervalActive) {
            const intervalId = setInterval(() => {
                fetchArtworks();
            }, 1000);

            return () => {
                clearInterval(intervalId);
            };
        }
    }, [retryIntervalActive]);

    useEffect(() => {
        if(callIsFinalized) {
            const intervalId = setInterval(() => {
                fetchFinalized();
            }, 1000);

            return () => {
                clearInterval(intervalId);
            }
        }
    }, [callIsFinalized]);

    useEffect(() => {
        const socket = io(SOCKET, {
            transports: ['websocket'],
            forceNew: false
          });

        socket.on('connect', () => {
          console.log('Conectado al servidor de Socket.IO');
        });

        socket.on('mensajeDesdeServidor', (mensaje) => {
          if(mensaje == 'ko') {
            window.location.href = '/';
          } else {
            setRetryIntervalActive(true);
          }
        });

        return () => {
          socket.disconnect();
        };
      }, []);

    return (
        <div className="home-container d-flex justify-content-center align-items-center">
            {Object.keys(artworksToReveal).length === 0 ? (

                <div>
                    <video
                        className="home-video"
                        muted
                        playsInline
                        loop
                        autoPlay
                    >
                        <source src="/videos/home-10min.mp4" type="video/mp4" />
                    </video>
                </div>

            ) : (

                <div>
                    {mona === null ? (

                        <div>
                            <video
                                muted
                                playsInline
                                autoPlay
                                onEnded={printMona}
                            >
                                <source src="/videos/reveal-countdown.mp4" type="video/mp4" />
                            </video>
                        </div>

                    ) : (

                        <img src={mona} />

                    )}

                </div>
            )}

        </div>
    );
}
